import Api from "@/api/Api";

export default {
  Get() {
    return Api().get("/email/template");
  },
  //   getDetail(id) {
  //     return Api().get(`/menu/${id}`);
  //   },
  //   GetOptionmenuRole(role) {
  //     return Api().get(`/menu/getmenu?role=${role}`);
  //   },
  Update(id, data) {
    return Api().put(`/email/template/${id}`, data);
  },

  //   UploadImage(id, data) {
  //     return Api().post(`menu/upload/${id}`, data);
  //   },
};
